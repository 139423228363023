export default {
  actions: {
    ok: 'OK'
  },
  accessibility: {
    consultPage: 'Aller à la page',
    loadingPage: 'Chargement de la page en cours',
    logoClicSanteAlt: "Cliquez ici pour retourner à l'accueil",
    alternateLogoClicSanteAlt: 'Cliquez ici pour ouvrir la carte de Noël',
    cancelApptMailImgAlt:
      "Courriel de confirmation de rendez-vous avec un bouton d'annulation dans le bas"
  },
  base: {
    clicsante: {
      formattedName: 'Clic Santé',
      serviceName: 'Clic Santé',
      newsletterName: 'Info Clic Santé',
      welcomeTitle: 'Bienvenue sur le réseau Clic Santé',
      error404: 'Erreur, page inexistante'
    },
    cookieConsent: {
      message:
        '<b>Utilisation de témoins de connexion : </b>' +
        'Notre site web utilise des témoins essentiels pour son fonctionnement et à l’utilisation de ses fonctions. Nous utilisons également des témoins pour recueillir des informations agrégées sur les interactions des utilisateurs afin d’améliorer sa performance via nos partenaires. Nous n’utilisons pas de témoins pour vous identifier, localiser, ou vous profiler.',
      linkLabel:
        'Pour plus d’informations sur notre utilisation des témoins, veuillez consulter notre Politique relative aux témoins de connexion ',
      buttonLabel: 'Ok',
      urlPath: '/confidentialite'
    },
    errors: {
      title: 'Un problème est survenu',
      failed: 'Une erreur est survenue, veuillez réessayer',
      requiredField: 'Ce champ est requis',
      invalidEmail: 'Le format du courriel est invalide',
      invalidPostalCode: 'Le code postal est invalide',
      postalCodeNotFound: 'Impossible de trouver le code postal saisi',
      invalidAppointmentNumber: 'Le numéro du rendez-vous est invalide',
      invalidNam: "Le numéro d'assurance maladie est invalide",
      invalidPhone: 'Le numéro de téléphone est invalide',
      minCharacters: 'Veuillez saisir au moins 3 caractères',
      invalidWordSearch: 'Veuillez préciser votre recherche',
      internalCodes: {
        geocode: {
          not_found: 'Impossible de trouver le code postal {address}'
        }
      }
    },
    success: {
      cancelAppointment: {
        withMultipleFactor: 'Un courriel ou un sms vous a été envoyé'
      }
    }
  },
  carousel: {
    profile: '{0} ou {1} Clic Santé afin de bénéficier de fonctionnalités supplémentaires',
    loginLink: 'Connectez-vous',
    registerLink: 'créez votre compte'
  },
  banner: {
    text: ' au compte Clic Santé pour simplifier la gestion de vos rendez-vous et ceux de votre famille',
    register: 'Inscrivez-vous'
  },
  createProfile: {
    title: 'Créez un compte sécurisé sans frais',
    text1: 'Simplifiez votre prise de rendez-vous.',
    text2: "Accédez à l'historique de vos rendez-vous.",
    text3: 'Gérez les rendez-vous de votre famille.',
    ctaSignUp: 'Créer mon compte',
    checkbox: 'Ne plus afficher ce message',
    ctaSignInText: 'Vous êtes déjà membre?',
    ctaSignInURLText: 'Connectez-vous'
  },
  marketing: {
    placeholder: {
      region: 'Indiquez votre région',
      email: 'Indiquez votre courriel'
    },
    btn: {
      subscribe: "M'inscrire",
      back: "Retour à la page d'accueil"
    },
    register: {
      success: 'Votre inscription est complétée',
      error: 'Votre inscription à échoué... Veuillez essayer plus tard'
    },
    regionNotAvailable: "Le processus d'inscription n'est pas disponible pour le moment"
  },
  header: {
    accessibilitySkipToContentLink: 'Passer au contenu principal',
    clicsante: {
      title1: 'Réservez votre rendez-vous',
      title2: ' santé dès maintenant!',
      menu: {
        profileConnect: 'Créer mon compte / Se connecter',
        takeAppt: 'Prendre un rendez-vous',
        solution: 'Solution de prise de rendez-vous',
        solutionLink: 'https://clicsante.net/',
        cancelAppt: 'Annuler un rendez-vous',
        contactUs: 'Contactez-nous',
        services: 'Voir toutes les catégories',
        faq: 'FAQ',
        job: "Offres d'emploi",
        terms: "Modalités et conditions d'utilisation",
        policy: 'Politique de confidentialité',
        cookiePolicy: 'Politique relative aux témoins de connexion',
        locale: 'FR'
      }
    },
    profile: {
      menu: {
        myProfile: 'Mon compte',
        disconnect: 'DÉCONNEXION'
      },
      loginText: 'Se connecter',
      loggedText: 'Mon compte',
      createMyAccount: 'Créer mon compte',
      notification: {
        title:
          'Connectez-vous ou créer votre profil afin de bénéficier de fonctionnalités supplémentaires',
        button: "Se connecter / s'inscrire"
      }
    }
  },
  footer: {
    clicsante: {
      slogan: 'Faites partie du réseau Clic Santé!',
      social: 'Aimez notre page facebook',
      social2: '<< Pour un meilleur accès aux soins de santé >>',
      citizensMenus: {
        title: 'Citoyens',
        takeAppt: 'Prendre un rendez-vous',
        cancelAppt: 'Annuler un rendez-vous',
        services: 'Registre des services',
        vaccineProof: 'Preuve vaccinale',
        vaccineProofUrl: 'https://covid19.quebec.ca/PreuveVaccinale/fr',
        solution: 'Solution de prise de rendez-vous',
        faq: 'FAQ'
      },
      jobsMenus: {
        title: 'Carrières',
        job: 'Postulez dès maintenant!'
      },
      usersMenus: {
        terms: "Modalités et conditions d'utilisation",
        policy: 'Politique de confidentialité',
        cookiePolicy: 'Politique relative aux témoins de connexion'
      },
      mobileMenus: {
        takeAppt: 'Prendre un RDV',
        cancelAppt: 'Annuler un RDV',
        loginText: 'Se connecter',
        loggedText: 'Mon compte'
      }
    }
  },
  cancel: {
    title: 'Trois façons d’annuler votre rendez-vous réservé sur Clic Santé :',
    itemOne: 'À partir de votre courriel de confirmation',
    itemTwo: 'À partir d’un courriel de rappel',
    itemThree: 'À partir du site de réservation de votre établissement de santé',
    subtitle: 'Comment retrouver le site de réservation de votre établissement ?',
    stepOne:
      "Utilisez le moteur de recherche dans la page d'accueil pour localiser votre établissement. Entrez&nbsp;<i><strong>le service et votre code postal</strong></i>. Cliquez ensuite sur&nbsp;<i><strong>Rechercher</strong></i>.",
    stepTwo:
      'Cliquez sur l\'onglet&nbsp;<i><strong>"Annuler un rendez-vous"</strong></i>&nbsp;en haut de la page',
    stepThree: 'Complétez le formulaire',
    cantCancel: "Vous n'arrivez pas à annuler votre rendez-vous de vaccination contre la COVID-19?",
    phoneNumber: '1 877 644-4545 (sans frais)',
    couponLostSuccess: 'Courriel envoyé avec succès',
    couponLostSuccessNoContent: 'Aucun rendez-vous relié à ce courriel',
    errors: {
      invalidCouponNumber: 'Numéro de coupon invalide',
      appointmentDateInvalid: 'Date du rendez-vous invalide',
      couponNotAssociated: 'Aucun rendez-vous associé à ce coupon'
    }
  },
  cancelAppointment: {
    title: 'Vous pouvez annuler votre rendez-vous de 3 manières:',
    withAppointment: {
      title:
        'En cliquant sur le bouton Annuler dans un courriel de confirmation ou de rappel (voir l’image ci-dessous)'
    },
    withMultipleFactor: {
      title: 'Avec votre adresse courriel et votre numéro de coupon',
      subtitle:
        'Important, vous devez inscrire la même adresse courriel que celle inscrite lors de la réservation du rendez-vous.',
      description:
        "Entrez votre numéro de confirmation dans la zone de texte suivante et cliquez sur le bouton ''Annuler mon rendez-vous''."
    },
    withoutCoupon: {
      title: 'Vous avez perdu votre numéro de coupon ?',
      subtitle:
        "Retrouvez vos rendez-vous à venir en utilisant l'adresse courriel utilisée lors de la prise de rendez-vous.",
      description: 'Entrez votre courriel pour recevoir votre liste de rendez-vous.'
    },
    errors: {
      invalidCouponNumber: 'Le format du numéro de coupon invalide',
      invalidEmail: 'Le format du courriel est invalide',
      invalidPhone: 'Le format numéro de cellulaire est incorrect',
      invalidDate: 'La date est invalide',
      requiredField: 'Le champ est requis'
    },
    inputs: {
      couponLabel: 'Numéro de coupon',
      dateLabel: 'Date du rendez-vous',
      thirdFactorLabel: 'Courriel ou numéro de cellulaire',
      emailLabel: 'Courriel'
    },
    cancelButton: 'Annuler mon rendez-vous',
    reminderButton: 'Envoyez moi un courriel'
  },
  contactUs: {
    title: 'contactez-nous',
    subtitle1: 'Je suis un citoyen',
    subtitle2: 'Je suis un établissement de soins de santé',
    content1:
      'Si vous avez besoin d’assistance pour prendre un rendez-vous dans Clic santé, contactez <strong>Services Québec</strong> au numéro sans frais <a href="tel:18776444545">1-877-644-4545</a>',
    content2:
      "Pour toute question concernant les services offerts, pour annuler ou modifier un rendez-vous (si vous n’avez pas votre numéro de coupon ou votre courriel de confirmation), veuillez contacter <strong>l'établissement de santé</strong> auprès duquel vous avez pris rendez-vous.",
    content3:
      "Pour rejoindre le <strong>département des ventes</strong> écrivez à l'adresse suivante :",
    content4:
      'Veuillez noter que les messages qui ne sont pas en lien avec les ventes ne seront pas traités.'
  },
  notFound: {
    title: 'Page introuvable (Erreur 404)',
    content1: 'Nous sommes désolés. La recherche effectuée n’a pas pu être finalisée.',
    content2: 'Le lien que vous avez inscrit a été déplacé ou modifié.',
    thanks: 'Merci de votre compréhension.',
    linkToPortal: "Retourner à l'accueil"
  },
  errors: {
    generic: 'Une erreur est survenue, veuillez réessayer',
    appointments: {
      'update-job': {
        'not-found': {
          title: 'Session expirée',
          message:
            "Le délai pour la prise de votre rendez-vous est expiré.<br /><br />Si votre précédente demande n'a pas été complétée, veuillez recommencer en choisissant le service à nouveau."
        },
        forbidden: {
          title: 'Erreur',
          messages: ''
        },
        'token-invalid': {
          title: 'Code invalide',
          message:
            "Le code que vous avez saisi est erroné. Svp, veuillez entrer un code de vérification d'identité valide.<br />Si le code inscrit est bien celui indiqué dans votre courriel de vérification d'identité, veuillez recommencer le processus d'identification."
        },
        'ip-mismatch': {
          title: 'Erreur',
          message: "l'adresse IP ne corresponds pas"
        },
        'not-in-priority-group': {
          title: 'Message important',
          message:
            "Vous n'êtes pas encore autorisé à prendre votre rendez-vous.<br /><br />" +
            'Pour connaître le calendrier pour votre groupe cible, consultez le site suivant : <a href="https://xn--qubec-csa.ca/vaccinCOVID" target="_blank">Québec.ca/vaccinCOVID</a>'
        },
        'pre-authorization-invalid': {
          title: 'Erreur',
          message:
            '<strong>Nous éprouvons des difficultés à vous identifier.</strong><br /><br />' +
            "'Le lien d'invitation pourrait être invalide, expiré ou déjà utilisé. Des erreurs de saisie d’informations nous empêchent de compléter votre demande de devancement de rendez-vous en ligne de façon sécuritaire.<br /><br />" +
            'Si vous avez été vacciné en <strong>pharmacie</strong> et que vous éprouvez des difficultés à prendre ou à modifier votre rendez-vous en ligne, nous vous invitons à communiquer avec cette dernière.<br /><br />' +
            "Voici les options qui s'offrent à vous :<br /><br />" +
            "1- <strong>Créez votre compte</strong> ClicSanté dès maintenant ou <strong>Connectez-vous</strong> si vous avez déjà un compte afin d'être authorisé à prendre un rendez-vous malgré les problèmes d'identifications actuels.<br /><br />" +
            '2- Vous pouvez vous présenter dans une <strong>clinique de vaccination sans rendez-vous</strong> de votre région. Consulter <a href="https://www.quebec.ca/sante/problemes-de-sante/a-z/coronavirus-2019/deroulement-vaccination-contre-la-covid-19#c104580" target="_blank">la liste des sites de vaccination sans rendez-vous</a>.<br /><br />'
        },
        'previous-dose-in-the-future': {
          title: 'Message important',
          message:
            "<strong>Vous n'êtes pas autorisé à prendre votre rendez-vous.</strong><br /><br />" +
            'Pour connaître le calendrier pour votre groupe cible, consultez le site suivant : <a href="https://xn--qubec-csa.ca/vaccinCOVID" target="_blank">Québec.ca/vaccinCOVID</a>.'
        },
        'postal-code-not-in-remote-area': {
          title: 'Message important',
          message:
            'Le code postal inscrit ne fait pas partie des zones desservies pour les citoyens de 18 ans ou plus habitant l’une des régions éloignées identifiées.'
        }
      }
    },
    validation: {
      'invalid-appointmentReference': {
        'custom-validator-failure': {
          title: 'Message important',
          message:
            '<p><strong>L’une des informations inscrites est incorrecte.</strong></p>' +
            '<p>Veuillez vérifier les informations inscrites dans les champs suivants&nbsp;:</p>' +
            '<ul style="list-style-type: \'-  \';">' +
            "<li><strong>Numéro d'assurance maladie</strong><div>Validez chaque caractère du numéro présent sur votre carte d’assurance maladie</div></li>" +
            '<li><strong>Numéro de coupon</strong><div>Valider la composition en lettres et chiffres du numéro de coupon de rendez-vous</div></li>' +
            '<li><strong>Courriel</strong><div>Validez le format de l’adresse courriel</div></li>' +
            '<li><strong>Numéro de téléphone</strong><div>Validez le format du numéro de téléphone</div></li>' +
            '</ul>' +
            '<p></p>' +
            "<p>Si vous éprouvez toujours des difficultés, voici les options qui s'offrent à vous&nbsp;:</p>" +
            '<ul>' +
            "<li><strong>Créez votre compte</strong> ClicSanté dès maintenant ou <strong>Connectez-vous</strong> si vous avez déjà un compte afin d'être authorisé à prendre un rendez-vous malgré les problèmes d'identifications actuels.</li>" +
            '<li>Vous pouvez vous présenter dans une <strong>clinique de vaccination sans rendez-vous</strong> de votre région. Consulter <a href="https://www.quebec.ca/sante/problemes-de-sante/a-z/coronavirus-2019/deroulement-vaccination-contre-la-covid-19#c104580" target="_blank">la liste des sites de vaccination sans rendez-vous</a>.</li>' +
            '</ul>'
        }
      },
      'invalid-phone': {
        invalid: {
          title: 'Message important',
          message:
            '<p><strong>L’une des informations inscrites est incorrecte.</strong></p>' +
            '<p>Veuillez vérifier les informations inscrites dans les champs suivants&nbsp;:</p>' +
            '<ul style="list-style-type: \'-  \';">' +
            "<li><strong>Numéro d'assurance maladie</strong><div>Validez chaque caractère du numéro présent sur votre carte d’assurance maladie</div></li>" +
            '<li><strong>Numéro de coupon</strong><div>Valider la composition en lettres et chiffres du numéro de coupon de rendez-vous</div></li>' +
            '<li><strong>Courriel</strong><div>Validez le format de l’adresse courriel</div></li>' +
            '<li><strong>Numéro de téléphone</strong><div>Validez le format du numéro de téléphone</div></li>' +
            '</ul>' +
            '<p></p>' +
            "<p>Si vous éprouvez toujours des difficultés, voici les options qui s'offrent à vous&nbsp;:</p>" +
            '<ul>' +
            "<li><strong>Créez votre compte</strong> ClicSanté dès maintenant ou <strong>Connectez-vous</strong> si vous avez déjà un compte afin d'être authorisé à prendre un rendez-vous malgré les problèmes d'identifications actuels.</li>" +
            '<li>Vous pouvez vous présenter dans une <strong>clinique de vaccination sans rendez-vous</strong> de votre région. Consulter <a href="https://www.quebec.ca/sante/problemes-de-sante/a-z/coronavirus-2019/deroulement-vaccination-contre-la-covid-19#c104580" target="_blank">la liste des sites de vaccination sans rendez-vous</a>.</li>' +
            '</ul>'
        }
      }
    },
    queue: {
      communication: {
        message_type: {
          unknown: {
            title: 'Message important',
            message:
              '<p><strong>Selon nos registres, aucun courriel ou cellulaire n’a été enregistré lors de la prise d’un rendez-vous précédent. Nous ne pouvons procéder à l’envoi de votre code de validation.</strong></p>' +
              "<p>Voici les options qui s'offrent à vous&nbsp;:</p>" +
              '<ul>' +
              "<li><strong>Créez votre compte</strong> ClicSanté dès maintenant ou <strong>Connectez-vous</strong> si vous avez déjà un compte afin d'être authorisé à prendre un rendez-vous malgré les problèmes d'identifications actuels.</li>" +
              '<li>Vous pouvez vous présenter dans une <strong>clinique de vaccination sans rendez-vous</strong> de votre région. Consulter <a href="https://www.quebec.ca/sante/problemes-de-sante/a-z/coronavirus-2019/deroulement-vaccination-contre-la-covid-19#c104580" target="_blank">la liste des sites de vaccination sans rendez-vous</a>.</li>' +
              '</ul>'
          }
        }
      }
    }
  },
  faq: {
    topics: {
      title: 'Votre question est à quel sujet ?',
      items: {
        'vaccination-covid': 'Vaccination Covid-19',
        'depistage-covid': 'Dépistage Covid-19',
        'clic-sante-account': 'Comptes citoyens',
        'my-favorites-places': 'Mes lieux favoris'
      }
    },
    questions: {
      title: 'Votre question concerne ',
      subtitle:
        'Choisissez une question parmi les suivantes. En tout temps, vous pouvez cliquer sur un des menus pour revenir à l’étape précédente.',
      button: 'Revenir',
      topic_description: {
        'vaccination-covid': 'la vaccination contre la covid-19',
        'depistage-covid': 'le dépistage pour la covid-19',
        'clic-sante-account': 'les comptes citoyens',
        'my-favorites-places': 'les lieux favoris'
      }
    },
    noData: 'Aucune donné pour le sujet'
  },
  logoutConfirmationModal: {
    title: 'Déconnexion de mon compte Clic Santé',
    text: 'Êtes-vous certain de vouloir vous déconnecter?',
    cancel: 'Annuler',
    logout: 'Oui, déconnectez-moi'
  },
  paths: {
    cancelAppt: 'annuler-un-rendez-vous',
    services: 'services',
    contactUs: 'contactez-nous',
    terms: 'termes-et-conditions',
    policy: 'confidentialite',
    cookiePolicy: 'politique-temoins-connexion',
    faq: 'questions-reponses'
  },
  categoryDescription: {
    'consultation-pharmacist': {
      title: 'Bienvenue dans la catégorie <strong>Consultation en pharmacie!</strong>',
      text:
        '<p>Cette catégorie permet de réserver un rendez-vous pour consulter un professionnel de la santé en pharmacie.</p>' +
        '<p><strong>Sélectionnez une sous-catégorie</strong> pour découvrir les services assurés (conformément aux lois 31 et 41) qui sont offerts dans une pharmacie près de chez vous. Certaines restrictions peuvent s’appliquer selon vos critères d’éligibilité.</p>' +
        '</p>Pour découvrir les nombreux services non assurés également disponibles en pharmacie, consultez les différentes catégories sur <a href="https://www.clicsante.ca" target="_blank">www.clicsante.ca</a>.</p>'
    },
    closeLabel: 'Fermer la description du service'
  },
  'category-information': {
    text:
      '<span>Veuillez noter que certaines catégories ont été modifiées. Pour accéder aux services liés à la COVID-19, veuillez vous référer aux catégories « Dépistage » ou « Vaccination ».<br />' +
      'Nous vous invitons également à utiliser la barre de recherche pour une recherche plus précise. Bonne visite!</span>',
    closeLabel: 'Fermer la description du service'
  }
}
